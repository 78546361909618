/* for debugging */
@keyframes react-widgets-autofill-start {
  from {
    /**/ }
  to {
    /**/ } }

@keyframes react-widgets-autofill-cancel {
  from {
    /**/ }
  to {
    /**/ } }

.rw-btn, .rw-picker-btn, .rw-input, .rw-detect-autofill, .rw-dropdownlist-search, .rw-time-part-input, .rw-time-part-meridiem, .rw-multiselect-input, .rw-multiselect-tag-btn {
  padding: 0;
  margin: 0;
  border: none;
  color: inherit;
  box-shadow: none;
  background: none;
  font: inherit;
  line-height: inherit;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  outline: 0; }

.rw-input:disabled, .rw-detect-autofill:disabled, .rw-dropdownlist-search:disabled, .rw-time-part-input:disabled, .rw-multiselect-input:disabled {
  opacity: 1; }

.rw-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .rw-btn:focus {
    outline: none; }
  .rw-btn:disabled,
  fieldset[disabled] .rw-btn,
  .rw-state-disabled .rw-btn,
  .rw-state-readonly .rw-btn {
    cursor: inherit; }
  fieldset[disabled] .rw-btn {
    pointer-events: none; }

.rw-picker-caret,
.rw-picker-btn {
  color: #212529; }

.rw-picker-btn:hover {
  background-color: #e9ecef; }

.rw-picker-btn:active {
  background-color: #dee2e6; }

.rw-picker-btn:disabled,
fieldset[disabled] .rw-picker-btn {
  background-color: #e9ecef; }

.rw-input-addon, .rw-input-addon.rw-picker-btn {
  border-left: #ccc 1px solid; }
  [dir='rtl'] .rw-input-addon, [dir='rtl'] .rw-input-addon.rw-picker-btn {
    border-right: #ccc 1px solid;
    border-left: none; }

.rw-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.rw-widget {
  border: none;
  color: #212529;
  font-size: 1em;
  font-family: inherit;
  outline: none;
  position: relative; }
  .rw-widget,
  .rw-widget *, .rw-widget:after, .rw-widget:before,
  .rw-widget *:after,
  .rw-widget *:before {
    box-sizing: border-box; }

.rw-state-readonly,
.rw-state-disabled,
fieldset[disabled] .rw-widget {
  cursor: not-allowed; }

.rw-widget-picker {
  display: grid;
  overflow: hidden;
  min-height: 38px;
  background-color: #fff;
  border: #ccc 1px solid;
  border-radius: 4px;
  outline: none;
  grid-template: 1fr/1fr 1.9em;
  width: 100%; }
  .rw-state-focus .rw-widget-picker {
    border-color: #80bdff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    transition: box-shadow 0.15s ease-in-out; }
    .rw-state-focus .rw-widget-picker.rw-widget-input {
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25), inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.rw-widget-input {
  color: #495057;
  background-color: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .rw-widget-input.rw-state-disabled,
  .rw-state-disabled .rw-widget-input,
  fieldset[disabled] .rw-widget-input {
    background-color: #e9ecef; }

.rw-input {
  padding: 0 0.857em; }
  .rw-input[type='text']::-ms-clear {
    display: none; }
  .rw-input:disabled, .rw-input:read-only {
    cursor: inherit; }

.rw-placeholder,
.rw-input::placeholder {
  color: #999; }
  .rw-state-disabled .rw-placeholder, .rw-state-disabled
  .rw-input::placeholder {
    color: #999; }

.rw-detect-autofill:-webkit-autofill {
  animation-name: react-widgets-autofill-start;
  transition: background-color 50000s ease-in-out 0s; }

.rw-detect-autofill:not(:-webkit-autofill) {
  animation-name: react-widgets-autofill-cancel; }

.rw-webkit-autofill .rw-widget-container,
.rw-input:-webkit-autofill {
  background-color: #e8f0fe !important;
  background-image: none !important;
  color: black !important; }

.rw-list {
  font-size: 1em;
  overflow-x: visible;
  overflow-y: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em; }

.rw-list-option, .rw-list-option-create {
  user-select: none;
  color: #212529;
  cursor: pointer;
  border: 1px solid transparent; }
  .rw-list-option:hover, .rw-list-option-create:hover,
  [data-intent='keyboard'] .rw-list-option.rw-state-focus:not(.rw-state-selected),
  [data-intent='keyboard'] .rw-state-focus.rw-list-option-create:not(.rw-state-selected) {
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .rw-list-option.rw-state-selected, .rw-state-selected.rw-list-option-create {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff; }
  .rw-list-option.rw-state-disabled, .rw-state-disabled.rw-list-option-create {
    cursor: not-allowed;
    background-color: transparent;
    border-color: transparent;
    color: #dee2e6; }

.rw-list-empty, .rw-list-option, .rw-list-option-create,
.rw-list-optgroup {
  padding: 0.25em 1.5em;
  outline: none; }

.rw-list-optgroup {
  font-weight: bold;
  padding-top: 7px; }

.rw-list-option-create {
  display: block;
  font-size: 1em;
  padding: 0.25em 1.5em; }

.rw-listbox {
  border-radius: 4px;
  background-color: #fff;
  border: #ccc 1px solid; }
  .rw-listbox.rw-state-focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25), inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: box-shadow 0.15s ease-in-out; }
  .rw-listbox.rw-state-disabled > *,
  fieldset[disabled] .rw-listbox > * {
    pointer-events: none; }
  .rw-listbox.rw-state-readonly > * {
    cursor: inherit; }

.rw-combobox-input {
  background-color: transparent;
  z-index: 1; }

.rw-dropdown-list {
  width: 100%; }

.rw-dropdown-list-input {
  padding: 0 0.857em;
  align-self: center;
  display: grid;
  min-width: 0;
  grid-template: 1fr / 1fr; }
  .rw-dropdown-list-input > * {
    grid-area: 1/1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  [dir='rtl'] .rw-dropdown-list-input {
    padding-right: 0.857em;
    padding-left: 0; }

.rw-number-picker-spinners {
  display: flex;
  flex-direction: column; }
  .rw-number-picker-spinners .rw-btn {
    flex: 1 1 0; }

.rw-calendar-popup {
  right: auto;
  min-width: 0;
  width: 22em; }

.rw-calendar {
  overflow: hidden; }

.rw-calendar-contained {
  border-radius: 4px;
  background-color: #fff;
  border: #ccc 1px solid; }

.rw-calendar-now {
  font-weight: bold; }

.rw-calendar-header {
  display: flex;
  padding: 0.8em; }

.rw-cell,
.rw-calendar-btn {
  line-height: 2em;
  border-radius: 4px; }
  .rw-cell:focus,
  .rw-calendar-btn:focus {
    z-index: 1;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    transition: box-shadow 0.15s ease-in-out; }
    [data-intent='mouse'] .rw-cell:focus, [data-intent='mouse']
    .rw-calendar-btn:focus {
      z-index: auto;
      box-shadow: none; }
  .rw-cell:hover,
  .rw-calendar-btn:hover {
    background-color: #e9ecef; }
  .rw-cell:active,
  .rw-calendar-btn:active {
    background-color: #dee2e6; }
  .rw-cell:disabled, .rw-cell.rw-state-disabled,
  .rw-calendar-btn:disabled,
  .rw-calendar-btn.rw-state-disabled {
    pointer-events: none;
    opacity: 0.35; }

.rw-calendar-btn-left,
.rw-calendar-btn-today,
.rw-calendar-btn-right,
.rw-calendar-btn-view {
  padding: 0.3em 0.6em; }

[dir='rtl'] .rw-calendar-btn-left, [dir='rtl']
.rw-calendar-btn-right {
  transform: scale(-1, 1); }

.rw-calendar-btn-view {
  font-weight: bold; }
  .rw-calendar-btn-view > :last-child {
    margin: 0 0.5em; }
  .rw-calendar-btn-view + * {
    margin-left: auto; }
    [dir='rtl'] .rw-calendar-btn-view + * {
      margin-left: 0;
      margin-right: auto; }

.rw-calendar-grid {
  outline: none;
  height: 14em;
  padding: 3px;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: #fff; }

.rw-head-cell {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 0.25em; }

.rw-cell {
  color: #212529;
  border-radius: 4px;
  cursor: pointer;
  line-height: normal;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.25em;
  outline: none; }
  .rw-cell.rw-state-selected {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff; }
  .rw-calendar-month .rw-cell {
    text-align: center; }

.rw-cell-off-range {
  color: #ced4da; }

.rw-calendar-transition-group {
  position: relative;
  overflow: hidden; }

.rw-calendar-transition {
  width: 100%;
  overflow: hidden;
  transition: transform 0.2s ease-in-out 0ms;
  transform: translate(0, 0); }
  @supports (transform-style: preserve-3d) {
    .rw-calendar-transition {
      transform: translate3d(0, 0, 0); } }

.rw-calendar-transition-next {
  -webkit-backface-visibility: hidden; }
  .rw-calendar-transition-top .rw-calendar-transition-next {
    transform: translate(0, -100%); }
    @supports (transform-style: preserve-3d) {
      .rw-calendar-transition-top .rw-calendar-transition-next {
        transform: translate3d(0, -100%, 0); } }
  .rw-calendar-transition-bottom .rw-calendar-transition-next {
    transform: translate(0, 100%); }
    @supports (transform-style: preserve-3d) {
      .rw-calendar-transition-bottom .rw-calendar-transition-next {
        transform: translate3d(0, 100%, 0); } }
  .rw-calendar-transition-right .rw-calendar-transition-next {
    transform: translate(-100%, 0); }
    @supports (transform-style: preserve-3d) {
      .rw-calendar-transition-right .rw-calendar-transition-next {
        transform: translate3d(-100%, 0, 0); } }
  .rw-calendar-transition-left .rw-calendar-transition-next {
    transform: translate(100%, 0); }
    @supports (transform-style: preserve-3d) {
      .rw-calendar-transition-left .rw-calendar-transition-next {
        transform: translate3d(100%, 0, 0); } }
  .rw-calendar-transition-next.rw-calendar-transition-active {
    transform: translate(0, 0); }
    @supports (transform-style: preserve-3d) {
      .rw-calendar-transition-next.rw-calendar-transition-active {
        transform: translate3d(0, 0, 0); } }

.rw-calendar-transition-prev {
  -webkit-backface-visibility: hidden;
  position: absolute;
  left: 0;
  top: 0; }
  .rw-calendar-transition-top .rw-calendar-transition-prev {
    transform: translate(0, 100%); }
    @supports (transform-style: preserve-3d) {
      .rw-calendar-transition-top .rw-calendar-transition-prev {
        transform: translate3d(0, 100%, 0); } }
  .rw-calendar-transition-bottom .rw-calendar-transition-prev {
    transform: translate(0, -100%); }
    @supports (transform-style: preserve-3d) {
      .rw-calendar-transition-bottom .rw-calendar-transition-prev {
        transform: translate3d(0, -100%, 0); } }
  .rw-calendar-transition-right .rw-calendar-transition-prev {
    transform: translate(100%, 0); }
    @supports (transform-style: preserve-3d) {
      .rw-calendar-transition-right .rw-calendar-transition-prev {
        transform: translate3d(100%, 0, 0); } }
  .rw-calendar-transition-left .rw-calendar-transition-prev {
    transform: translate(-100%, 0); }
    @supports (transform-style: preserve-3d) {
      .rw-calendar-transition-left .rw-calendar-transition-prev {
        transform: translate3d(-100%, 0, 0); } }

.rw-popup {
  display: flex;
  flex-direction: column; }
  .rw-popup > .rw-time-input {
    align-self: center;
    margin: 1em 0; }

.rw-time-input {
  display: inline-flex;
  min-height: 38px;
  align-items: center;
  background-color: #fff;
  border: #ccc 1px solid;
  border-radius: 4px;
  outline: none;
  cursor: text; }

.rw-time-part-input {
  padding: 0 0.5em; }
  .rw-time-part-input, .rw-time-part-input::placeholder {
    text-align: center; }

.rw-time-part-meridiem {
  padding: 0 0.5em;
  text-transform: lowercase;
  font-variant: small-caps; }
  .rw-time-part-meridiem :focus {
    outline: none; }

.rw-time-input-clear {
  padding: 0 0.4em;
  outline: none;
  opacity: 0; }
  .rw-time-input:hover .rw-time-input-clear.rw-show,
  .rw-time-input.rw-state-focus .rw-time-input-clear.rw-show {
    opacity: 1; }

.rw-multiselect-input {
  height: calc(38px - 1px * 2);
  padding: 0 0.857em;
  max-width: 100%; }
  .rw-multiselect-input:disabled, .rw-multiselect-input:read-only {
    cursor: inherit; }

.rw-multiselect-taglist {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  outline: none;
  cursor: text; }
  .rw-state-disabled .rw-multiselect-taglist {
    cursor: unset; }

.rw-multiselect-tag {
  display: inline-flex;
  margin-left: calc( 38px * 0.115 - 1px);
  margin-top: calc( 38px * 0.115 - 1px);
  min-height: calc( 38px * 0.77);
  border-radius: 3px;
  background-color: #dee2e6;
  border: 1px solid #dee2e6;
  cursor: default;
  max-width: 100%;
  align-items: center;
  overflow: hidden; }
  .rw-multiselect-tag:focus {
    outline: none; }
  [dir='rtl'] .rw-multiselect-tag {
    margin-left: 0;
    margin-right: calc( 38px * 0.115 - 1px);
    padding: 0 0.35em 0 0.35em; }
  .rw-multiselect-tag.rw-state-focus {
    z-index: 1;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    transition: box-shadow 0.15s ease-in-out; }
  .rw-multiselect-tag.rw-state-disabled,
  fieldset[disabled] .rw-multiselect-tag {
    opacity: 0.65; }

.rw-multiselect-tag-label {
  padding: 0 0.35em 0 0.35em; }

.rw-multiselect-tag-btn {
  cursor: pointer;
  align-self: stretch;
  padding: 0 0.35em; }
  .rw-multiselect-tag-btn:focus {
    outline: none; }
  .rw-multiselect-tag-btn:disabled {
    cursor: inherit; }

.rw-autocomplete .rw-select {
  position: absolute;
  display: block;
  width: auto;
  top: 0;
  bottom: 0;
  right: 0; }

.rw-popup-container {
  position: absolute;
  z-index: 1005;
  top: 100%;
  left: -1em;
  right: -1em;
  padding: 0 1em; }
  .rw-popup-container.rw-dropup {
    top: auto;
    bottom: 100%; }
  .rw-state-focus .rw-popup-container {
    z-index: 1006; }

.rw-slide-transition {
  margin-bottom: 1em; }
  .rw-dropup > .rw-slide-transition {
    margin-bottom: 0;
    margin-top: 1em; }

.rw-popup {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 2px;
  border-radius: 4px;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.175);
  border: #ccc 1px solid;
  background: #fff; }
  .rw-popup .rw-list {
    max-height: 250px; }
  .rw-dropup .rw-popup {
    margin-top: 0;
    margin-bottom: 2px;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.175); }

.rw-slide-transition {
  transition: transform 130ms, opacity 100ms; }

.rw-slide-transition-entering {
  overflow: hidden; }
  .rw-slide-transition-entering .rw-slide-transition {
    transform: translateY(0);
    opacity: 1;
    transition-timing-function: ease-out; }

.rw-slide-transition-exiting .rw-slide-transition {
  transition-timing-function: ease-in; }

.rw-slide-transition-exiting,
.rw-slide-transition-exited {
  overflow: hidden; }
  .rw-slide-transition-exiting .rw-slide-transition,
  .rw-slide-transition-exited .rw-slide-transition {
    opacity: 0;
    transform: translateY(-10%); }
  .rw-slide-transition-exiting.rw-dropup .rw-slide-transition,
  .rw-slide-transition-exited.rw-dropup .rw-slide-transition {
    opacity: 0;
    transform: translateY(10%); }

.rw-slide-transition-exited {
  display: none; }
